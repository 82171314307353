import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";


function NotFoundPage() {
  return (
    <Layout showHero={false}>
      <SEO title="404: Not found" />
      <div className="flex flex-col ">
        <StaticImage src="../images/mrisRabit.jpg" placeholder="blurred" alt="ウサギ" />
        <h2 className="bg-yellow-400 text-2xl font-bold inline-block my-8 p-3 text-center" >
          このページは存在しません
        </h2>
        <div className="text-center border-double border-4 border-light-blue-500 mx-6 hover:bg-blue-500 transition duration-300 ease-in-out">
        <Link to="/">Homeへ</Link>
        </div>
        
      </div>
    </Layout>
  );
}

export default NotFoundPage;
